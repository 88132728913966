import Layout from '../components/Layout'
// import { Link } from "gatsby"
import { useState, useEffect } from 'react'
import * as styles from '../styles/home.module.scss'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import how1 from '../images/home/how1.png'
import how2 from '../images/home/how2.png'
import how3 from '../images/home/how3.png'
import how4 from '../images/home/how4.png'

import banner from '../images/home/banner.png'

import avater1 from '../images/common/avater1.png'
import avater2 from '../images/common/avater2.png'
import avater3 from '../images/common/avater3.png'
import avater4 from '../images/common/avater4.png'
import avater5 from '../images/common/avater5.png'
import avater6 from '../images/common/avater6.png'

import what1 from '../images/home/what1.png'
import what2 from '../images/home/what2.png'
import what3 from '../images/home/what3.png'

import flow1 from '../images/home/flow1.png'
import flow2 from '../images/home/flow2.png'
import flow3 from '../images/home/flow3.png'
import flow4 from '../images/home/flow4.png'
import flow5 from '../images/home/flow5.png'
import flow6 from '../images/home/flow6.png'
import flow7 from '../images/home/flow7.png'

import tokenbg from '../images/home/tokenbg.png'
import faq from '../images/home/faq.png'
import faq_active from '../images/home/faq_active.png'
const Home = () => {
  const [showContent, setShowContent] = useState(0)
  const turnShow = e => {
    setShowContent(0)
    if (e.target.dataset.index) {
      const index = e.target.dataset.index * 1
      setShowContent(index)
      if (showContent === index) {
        setShowContent(0)
      }
    }
  }
  const settings = {
    infinite: true,
    speed: 500,
    vertical: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    // fade: true,
  }
  const [num, setNum] = useState(6)
  const makeSome = () => {
    const some = document.body.offsetWidth
    if (some) {
      if (some < 980) {
        setNum(5)
      }
      if (some < 769) {
        setNum(4)
      }
      if (some < 680) {
        setNum(3)
      }
      if (some < 426) {
        setNum(2)
      }
    } else {
      setNum(6)
    }
  }
  useEffect(() => {
    // window.addEventListener('resize', makeSome);
    makeSome()
    return () => {
      // window.removeEventListener('resize', makeSome);
    }
  }, [])
  return (
    <Layout>
      <section className={styles.home}>
        <div className={styles.banner}>
          <ul className={styles.left}>
            <li>Welcome to </li>
            <li>ChainTokenomics</li>
            <li>
              Old way of entrepreneurship to the new way of entrepreneurship
            </li>
            <li>
              <div>WHITEPAPER</div>
            </li>
          </ul>
          <div className={styles.right}>
            <img src={banner} alt="" />
          </div>
        </div>
        <div className={styles.banner_mobile}>
          <div className={styles.content}>
            <ul className={styles.left}>
              <li>Welcome to </li>
              <li>ChainTokenomics </li>
              <li>
                Old way of entrepreneurship to the new way of entrepreneurship
              </li>
            </ul>
            <div className={styles.right}>
              <img src={banner} alt="" />
            </div>
            <div className={styles.bttn}>WHITEPAPER</div>
          </div>
        </div>
        <div className={styles.autoBanner}>
          <Slider {...settings}>
            <div>
              <ul>
                {num === 6 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater5} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater6} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 5 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater5} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 4 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 3 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 2 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
            <div>
              <ul>
                {num === 6 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater5} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater6} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 5 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater5} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 4 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater4} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 3 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater3} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : num === 2 ? (
                  <>
                    <li>
                      <span>
                        <img src={avater1} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                    <li className={styles.line}></li>
                    <li>
                      <span>
                        <img src={avater2} alt="" />
                      </span>
                      <span>
                        <p>Austin Dean Ashford</p>
                        <p>$30.0</p>
                      </span>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </Slider>
        </div>
        <div className={styles.whatIs}>
          <h3>What is the CTIO?</h3>
          <h2>Understand Our Vision</h2>
          <p>Democratising fundraising and entrepreneurship</p>
          <p>
            This is the future of work - we are all stakeholders in the digital
            value chain.
          </p>
          <p>Old way of entrepreneurship to the new way of entrepreneurship</p>
        </div>
        <div className={styles.three}>
          <div className={styles.content}>
            <ul>
              <li>
                <div>
                  <img src={what1} alt="" />
                </div>
                <p>Audience</p>
                <p>The whole ecosystem of users</p>
              </li>
              <li>
                <div>
                  <img src={what2} alt="" />
                </div>
                <p>Role of the platform</p>
                <p>
                  Create an ecosystem of digital value chain participants, no
                  middleman, no broker
                </p>
              </li>
              <li>
                <div>
                  <img src={what3} alt="" />
                </div>
                <p>Business model</p>
                <p>$CTIO token. CTIO will charge gas fees, APY etc.</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.flow}>
          <div className={styles.content}>
            <h2>Overall concept flow</h2>
            <ul>
              <li>
                <div>
                  <img src={flow1} alt="" />
                </div>
                <h3>Create a DAO</h3>
                <p>A digital value chain DAO for your idea/project on CTIO</p>
              </li>
              <li>
                <div>
                  <img src={flow2} alt="" />
                </div>
                <h3>Stake stablecoin</h3>
                <p>
                  Participants can stake stablecoin to participate in your DAO
                </p>
              </li>
              <li>
                <div>
                  <img src={flow3} alt="" />
                </div>
                <h3>Perform tasks</h3>
                <p>
                  List missions to perform tasks around your digital value
                  chain/DAO
                </p>
              </li>
              <li>
                <div>
                  <img src={flow4} alt="" />
                </div>
                <h3>Earn tokens</h3>
                <p>
                  Participants that perform work on the mission earn tokens for
                  work that they do for the DAO
                </p>
              </li>
              <li>
                <div>
                  <img src={flow5} alt="" />
                </div>
                <h3>Exchange DAO</h3>
                <p>
                  Participants can “cash out” their tokens for real money by
                  exchanging their DAO or DVCT tokens via DEXs{' '}
                </p>
              </li>
              <li>
                <div>
                  <img src={flow6} alt="" />
                </div>
                <h3>Explore NFTs</h3>
                <p>NFTs can also be issued.</p>
              </li>
              <li>
                <div>
                  <img src={flow7} alt="" />
                </div>
                <h3>Future</h3>
                <p>These NFTs can be backed by assets</p>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={styles.how}>
          <div className={styles.content}>
            <h2>How CTIO Works</h2>
            <div className={styles.floatt}>
              <ul>
                <li>
                  <img src={how1} alt="" />
                  <h3>Create a DAO </h3>
                  <p>Select a template</p>
                </li>
                <li>
                  <div className={styles.rowright}>
                    <i></i>
                  </div>
                </li>

                <li>
                  <img src={how2} alt="" />
                  <h3>Create your token </h3>
                  <p>We make sure that it passes the Howey Test</p>
                </li>
                <li>
                  <div className={styles.rowright}>
                    <i></i>
                  </div>
                </li>
                <li>
                  <img src={how3} alt="" />
                  <h3>Create your own NFTs </h3>
                  <p>Select a template</p>
                </li>
                <li>
                  <div className={styles.rowright}>
                    <i></i>
                  </div>
                </li>
                <li>
                  <img src={how4} alt="" />
                  <h3>Create your missions </h3>
                  <p>Select a template</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.token}>
          <h2>CTIO Token</h2>
          <div className={styles.show}>
            <div className={styles.bg}>
              <img src={tokenbg} alt="" />
            </div>
            <div className={styles.des}>
              <h3>Our Native Token $CTIO</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
                posuere risus quisque donec. Amet, velit etiam ornare ultricies
                vel, nisi, habitant at. Urna, nulla turpis quis malesuada
                praesent senectus elementum amet.{' '}
              </p>
            </div>
          </div>
          <div className={styles.show}>
            <div className={styles.des}>
              <h3>Exchange $CTIO with your own tokens $DVCT</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
                posuere risus quisque donec. Amet, velit etiam ornare ultricies
                vel, nisi, habitant at. Urna, nulla turpis quis malesuada
                praesent senectus elementum amet.{' '}
              </p>
            </div>
            <div className={styles.bg}>
              <img src={tokenbg} alt="" />
            </div>
          </div>
          <div className={styles.btn}>
            Learn more <i></i>
          </div>
        </div>
        <div className={styles.faqs} onClick={turnShow}>
          <ul>
            <li>FAQs</li>
            <li
              data-index={1}
              className={showContent === 1 ? `${styles.row_active}` : ``}
            >
              What is CTIO?
              <span data-index={1}>
                {' '}
                <img src={faq} data-index={1} alt="" />{' '}
                <img src={faq_active} data-index={1} alt="" />
              </span>
            </li>
            <li className={showContent === 1 ? `${styles.content_active}` : ``}>
              <p>Democratising fundraising and entrepreneurship</p>
              <p>
                This is the future of work - we are all stakeholders in the
                digital value chain.
              </p>
              <p>
                Old way of entrepreneurship to the new way of entrepreneurship
              </p>
            </li>
            <li
              data-index={2}
              className={showContent === 2 ? `${styles.row_active}` : ``}
            >
              What is CTIO?
              <span data-index={2}>
                {' '}
                <img src={faq} data-index={2} alt="" />
                <img src={faq_active} data-index={2} alt="" />
              </span>
            </li>
            <li className={showContent === 2 ? `${styles.content_active}` : ``}>
              <p>Democratising fundraising and entrepreneurship</p>
              <p>
                This is the future of work - we are all stakeholders in the
                digital value chain.
              </p>
              <p>
                Old way of entrepreneurship to the new way of entrepreneurship
              </p>
            </li>
            <li
              data-index={3}
              className={showContent === 3 ? `${styles.row_active}` : ``}
            >
              What is CTIO?
              <span data-index={3}>
                {' '}
                <img src={faq} data-index={3} alt="" />
                <img src={faq_active} data-index={3} alt="" />
              </span>
            </li>
            <li className={showContent === 3 ? `${styles.content_active}` : ``}>
              <p>Democratising fundraising and entrepreneurship</p>
              <p>
                This is the future of work - we are all stakeholders in the
                digital value chain.
              </p>
              <p>
                Old way of entrepreneurship to the new way of entrepreneurship
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}
export default Home
